import React from "react";
// import Image from "next/image";
import { COLOR, FONT_SIZE } from "constants/styles";
import { Container } from "components/UI/Container";

export const TrustedBy = () => {
  return (
    <>
      <div className="stripe-end">
        <Container content noBackground>
          <div className="trusted-by">
            <h3 className="trusted-by-title">
              Trusted by thousands of satisfied customers
            </h3>
            {/* <h3 className="trusted-by-title">Trusted by Industry Leaders</h3> */}

            {/* <div className="companies">
              <div className="company">
                <a
                  href="https://cardanofoundation.org/"
                  target="_blank"
                  rel="noreferrer"
                  className="company-href"
                >
                  <Image
                    src="/images/cardano-foundation.png"
                    width={150}
                    height={50}
                    alt="Cardano Foundation"
                    title="Cardano Foundation"
                  />
                </a>
              </div>

              <div className="company">
                <a
                  href="https://namiwallet.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="company-href"
                >
                  <Image
                    src="/images/nami.svg"
                    width={150}
                    height={50}
                    alt="Nami Wallet"
                    title="Nami Wallet"
                  />
                </a>
              </div>
              <div className="company">
                <a
                  href="https://www.emurgo.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="company-href"
                >
                  <Image
                    src="/images/emurgo.png"
                    width={174}
                    height={50}
                    alt="Emurgo"
                    title="Emurgo"
                  />
                </a>
              </div>

              <div className="company">
                <a
                  href="https://lucid.spacebudz.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="company-href"
                >
                  <Image
                    src="/images/lucid.svg"
                    width={150}
                    height={50}
                    alt="Lucid"
                    title="Lucid"
                  />
                </a>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
      <style jsx>{`
        .reasons {
          display: flex;
          justify-content: space-between;
          gap: 0px 48px;
        }

        .reason {
          flex: 1 1 100%;
        }
        .reason-title {
          font-size: ${FONT_SIZE.XL};
          font-weight: 500;
          margin-bottom: 8px;
        }
        .reason-description {
          font-size: ${FONT_SIZE.LG};
        }

        .stripe-end {
          display: flex;
          flex-direction: column;
          clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 100px), 0% 100%);
          background-image: linear-gradient(135deg, #4a15e9, #8d1fbc);
          background: ${COLOR.BG_LANDING_LIGHT};
          z-index: 2;
          margin-top: -100px;
          padding-top: 100px;
        }

        .companies {
          display: flex;
          flex-wrap: wrap;
          gap: 32px;
          justify-content: center;
          margin-top: 32px;
          margin-bottom: 168px;
        }

        .company {
          display: flex;
        }

        .company-href {
          display: flex;
          align-items: center;
        }

        .trusted-by {
          margin-top: 48px;
        }

        .trusted-by-title {
          text-align: center;
          font-size: ${FONT_SIZE.XXL};
          font-weight: 500;
          padding-bottom: 120px;
        }
      `}</style>
    </>
  );
};
