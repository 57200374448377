import React, { ReactElement } from "react";
import { MainLayout, FAQ, Pricing, Introduction } from "components";
import { CodeSnippets } from "components/layout/sections/CodeSnippets";
import { TrustedBy } from "components/layout/sections/TrustedBy";
import { Features } from "components/layout/sections/Features";

function Index(): ReactElement {
  return (
    <MainLayout title="Blockfrost.io - Cardano API">
      <Introduction />
      <TrustedBy />
      <Features />
      <CodeSnippets />
      <Pricing />
      <FAQ />
    </MainLayout>
  );
}

export default Index;
