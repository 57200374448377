import React from "react";
import { FaJava } from "react-icons/fa";
import {
  SiArduino,
  SiCsharp,
  SiElixir,
  SiGo,
  SiHaskell,
  SiJavascript,
  SiKotlin,
  SiPhp,
  SiPython,
  SiRuby,
  SiRust,
  SiScala,
  SiSwift,
} from "react-icons/si";

export const snippets = [
  {
    id: "javascript",
    label: "Node.js",
    link: "https://github.com/blockfrost/blockfrost-js-examples",
    logo: <SiJavascript />,
    example: `import { BlockFrostAPI } from '@blockfrost/blockfrost-js';
  
async function run() {
  const API = new BlockFrostAPI({
    projectId: 'YOUR API KEY HERE',
  });

  try {
    const latestBlock = await API.blocksLatest();
    console.log(latestBlock);
  } catch (error) {
    console.error(error);
  }
}

run();`,
  },
  {
    id: "python",
    label: "Python",
    link: "https://github.com/blockfrost/blockfrost-python",
    logo: <SiPython />,
    example: `from blockfrost import BlockFrostApi, ApiError, ApiUrls
  
api = BlockFrostApi(project_id='YOUR API KEY HERE')

try:
    address = api.address(
        address='addr1qxqs59lphg8g6qndelq8xwqn60ag3aeyfcp33c2kdp46a09re5df3pzwwmyq946axfcejy5n4x0y99wqpgtp2gd0k09qsgy6pz')
    print(address.type)
    for amount in address.amount:
        print(amount.quantity, amount.unit)

except ApiError as e:
    print(e)`,
  },
  {
    id: "rust",
    label: "Rust",
    link: "https://github.com/blockfrost/blockfrost-rust",
    logo: <SiRust />,
    example: `use blockfrost::{load, BlockFrostApi};

fn build_api() -> blockfrost::Result<BlockFrostApi> {
    let configurations = load::configurations_from_env()?;
    let project_id = configurations["project_id"].as_str().unwrap();
    let api = BlockFrostApi::new(project_id, Default::default());
    Ok(api)
}

#[tokio::main]
async fn main() -> blockfrost::Result<()> {
    let api = build_api()?;
    let genesis = api.genesis().await?;

    println!("{:#?}", genesis);
    Ok(())
}`,
  },
  {
    id: "csharp",
    label: "C#",
    link: "https://github.com/blockfrost/blockfrost-dotnet",
    logo: <SiCsharp />,
    example: `using System.IO;
using System.IO;
using Blockfrost.Api.Extensions;
using Blockfrost.Api.Models.Extensions;
using Blockfrost.Api.Services;
using Blockfrost.Api.Services.Extensions;
using Microsoft.Extensions.DependencyInjection;

/*
 * Parameters
 */
string apiKey = "YOUR_BLOCKFROST_PROJECT_ID";
string network = "NETWORK_OF_THE_PROJECT_ID";
string sender_address = "SENDER_ADDR";
string receiver_address = "RECEIVER_ADDR";
string signedTx = File.ReadAllText("path/to/your/signed/transaction");

/*
 * Init Services using apiKey and network
 */
var cardano = new ServiceCollection()
    .AddBlockfrost(network, apiKey)
    .BuildServiceProvider()
    .GetRequiredService<ICardanoService>();

/*
 * Show metrics for your account
 */
var metrics = await cardano.Metrics.GetMetricsAsync();
var opt = new System.Text.Json.JsonSerializerOptions() { WriteIndented = true };
System.Console.WriteLine($"Metrics: {metrics.ToJson(opt)}");

/*
 * Show sender UTxO
 */
var utxoSender = await cardano.Addresses.GetUtxosAsync(sender_address);
long totalSender = utxoSender.SumAmounts("lovelace");
System.Console.WriteLine($"Sender Total: {totalSender} lovelace");

/*
 * Sum receiver UTxO
 */
var utxoReceiver = await cardano.Addresses.GetUtxosAsync(receiver_address);
long totalReceiver = utxoReceiver.SumAmounts("lovelace");
System.Console.WriteLine($"Receiver Total: {totalReceiver} lovelace");

/*
 * Query tip
 */
var tip = await cardano.Blocks.GetLatestAsync();
long? latestSlot = tip.Slot;

System.Console.WriteLine($"Tip now at Epoch {tip.Epoch} Slot {tip.Slot} Block {tip.Height}");

/*
 * Send submit tx
 */
System.Console.WriteLine(signedTx);
string txid = await cardano.Transactions.PostTxSubmitAsync(signedTx);

System.Console.WriteLine($"Your Transaction was transmitted to the {network}");
System.Console.WriteLine($"https://explorer.cardano-{network}.iohkdev.io/en/transaction?id={txid}");

/*
 * Wait two blocks
 */
tip = await cardano.Blocks.WaitAsync(
    count: 2,
    interval: System.TimeSpan.FromSeconds(5),
    callback: latest => System.Console.WriteLine(latest.Slot),
    cancellationToken: System.Threading.CancellationToken.None
);
System.Console.WriteLine($"Tip now at Epoch {tip.Epoch} Slot {tip.Slot} Block {tip.Height}");`,
  },
  {
    id: "go",
    label: "Go",
    link: "https://github.com/blockfrost/blockfrost-go",
    logo: <SiGo />,
    example: `package main

import (
    "context"
    "fmt"
    "log"
    "github.com/blockfrost/blockfrost-go"
)

func main() {
    api, err := blockfrost.NewAPIClient(
        blockfrost.APIClientOptions{
            ProjectID: "YOUR_PROJECT_ID_HERE"
        },
    )
    if err != nil {
        log.Fatal(err)
    }

    info, err := api.Info(context.TODO())
    if err != nil {
        log.Fatal(err)
    }

    fmt.Printf("API Info:\n\tUrl: %s\n\tVersion: %s", info.Url, info.Version)
}`,
  },
  {
    id: "haskell",
    label: "Haskell",
    link: "https://github.com/blockfrost/blockfrost-haskell",
    logo: <SiHaskell />,
    example: `{-# LANGUAGE OverloadedStrings #-}
{-# LANGUAGE ScopedTypeVariables #-}
module Main
  where

import Blockfrost.Client

main = do
  prj <- projectFromEnv
  res <- runBlockfrost prj $ do
    latestBlocks <- getLatestBlock
    (ers :: Either BlockfrostError [AccountReward]) <-
        tryError $ getAccountRewards "gonnaFail"
    pure (latestBlocks, ers)

  print res`,
  },
  {
    id: "ruby",
    label: "Ruby",
    link: "https://github.com/blockfrost/blockfrost-ruby",
    logo: <SiRuby />,
    example: `require 'blockfrost-ruby'
    
blockfrost_mainnet = Blockfrostruby::CardanoMainNet.new('your-API-key')    
blockfrost.get_block_latest_transactions({ count: 20 })
      `,
  },
  {
    id: "swift",
    label: "Swift",
    link: "https://github.com/blockfrost/blockfrost-swift",
    logo: <SiSwift />,
    example: `import BlockfrostSwiftSDK

BlockfrostStaticConfig.basePath = "https://cardano-mainnet.blockfrost.io/api/v0"  // or leave default
BlockfrostStaticConfig.projectId = "your-project-id"
let api = CardanoAddressesAPI()

_ = api.getAddressDetails(
  address:
    "addr1q8zu4smzyf2r2mfqjd6tc6vxf2p8rccdfk82ye3eut2udkw9etpkygj5x4kjpym5h35cvj5zw83s6nvw5fnrnck4cmvshkfm4y"
) { resp in
  switch resp {
  case let .failure(err):
    // handle error here, \`err\` contains the error
    break
  case let .success(r):
    // \`r\` contains result of the call, here, it is of type \`AddressContentTotal\`
    break
  }
}`,
  },
  {
    id: "kotlin",
    label: "Kotlin",
    link: "https://github.com/blockfrost/blockfrost-kotlin",
    logo: <SiKotlin />,
    example: `import io.blockfrost.sdk_kotlin.api.CardanoAddressesApi
import io.blockfrost.sdk_kotlin.infrastructure.BlockfrostConfig

// Use default configuration, mainnet, project_id loaded from BF_PROJECT_ID env var
val api = CardanoAddressesApi(config = BlockfrostConfig.defaulMainNetConfig)

// coroutine call, returns model object
val details = api.getAddressDetails("addr1q8zu4smzyf2r2mfqjd6tc6vxf2p8rccdfk82ye3eut2udkw9etpkygj5x4kjpym5h35cvj5zw83s6nvw5fnrnck4cmvshkfm4y")`,
  },
  {
    id: "elixir",
    label: "Elixir",
    link: "https://github.com/blockfrost/blockfrost-elixir",
    logo: <SiElixir />,
    example: `defmodule MyApp.Application do
    def start(_type, _args) do
      children = [
        {Blockfrost, [
          network: :cardano_mainnet,
          name: CardanoMainNet,
          api_key: System.get_env("CARDANO_API_KEY"),
          retry_enabled?: true,
          retry_max_attempts: 3
        ]},
        {Blockfrost, [
          network: :ipfs,
          name: IPFS,
          api_key: System.get_env("IPFS_API_KEY"),
          retry_enabled?: false
        ]}
      ]
  
      Supervisor.start_link(children, strategy: :one_for_one, name: MyApp.Supervisor)
    end
  end`,
  },
  {
    id: "java",
    label: "Java",
    link: "https://github.com/blockfrost/blockfrost-java",
    logo: <FaJava />,
    example: `package io.blockfrost.sdk.api;
import io.blockfrost.sdk.api.model.Block;
import io.blockfrost.sdk.api.util.Constants;
import io.blockfrost.sdk.impl.BlockServiceImpl;

BlockService blockService = new BlockServiceImpl(Constants.BLOCKFROST_TESTNET_URL, "PROJECT-ID");
Block block = blockService.getLatestBlock();`,
  },
  {
    id: "scala",
    label: "Scala",
    link: "https://github.com/blockfrost/blockfrost-scala",
    logo: <SiScala />,
    example: `import io.blockfrost.sdk.BlockfrostClient
import io.blockfrost.sdk.api.{AssetsApi, BlockApi, IpfsApi}
import io.blockfrost.sdk.common._
import io.blockfrost.sdk.util.FuturePaginationSupport
import io.blockfrost.sdk.util.FutureResponseConverter.FutureResponseOps
import workspace.json4s.jackson.Serialization
import workspace.json4s.{DefaultFormats, Formats}
import sttp.client3.SttpBackend
import sttp.client3.asynchttpclient.future.AsyncHttpClientFutureBackend

import java.io.File
import scala.concurrent.ExecutionContext.Implicits.global
import scala.concurrent.Future

object Application extends App {
  implicit val formats: Formats = DefaultFormats
  implicit val serialization: Serialization.type = Serialization
  // SDK configuration for http client, concurrent requests and rate limiter
  implicit val sdkConfig: Config = Config(threadCount = 5, rateLimitPauseMillis = 500, readTimeoutMillis = 5000)
  // Explicitly pass you API key to the BlockfrostClient or set it to BLOCKFROST_API_KEY env variable
  val apiKey = "your-api-key"
  val sttpBackend: SttpBackend[Future, Any] = AsyncHttpClientFutureBackend()

  // Instantiate Mainnet BlockfrostClient
  val cardanoClient = BlockfrostClient[Future, Any](sttpBackend, Mainnet, apiKey)

  // Get latest Cardano block
  val latestBlockF: Future[BlockApi.BlockContent] = cardanoClient.getLatestBlock.extract

  // Get paginated assets: 10 elements from second page, sorted ascending
  val assetsPageF: Future[Seq[AssetsApi.AssetShort]] = cardanoClient.getAssets(SortedPageRequest.apply(count = 10, page = 2, order = Asc)).extract

  // Concurrently get all assets from all pages using 5 threads (configured in sdkConfig variable)
  val allAssetsF: Future[Seq[AssetsApi.AssetShort]] = FuturePaginationSupport.getAllPages((pr: PageRequest) => cardanoClient.getAssets(pr.asInstanceOf[SortedPageRequest]).extract, Some(Asc))
  }
}`,
  },
  {
    id: "php",
    label: "PHP",
    link: "https://github.com/blockfrost/blockfrost-php",
    logo: <SiPhp />,
    example: `<?php 
    use Blockfrost\Block\BlockService;
    use Blockfrost\Service;
  
    $projectId = "MY_PROJECT_ID";
    $blockService = new BlockService(Service::$NETWORK_CARDANO_MAINNET, $projectId);
  
    try {
      $res = $blockService->getLatestBlock();
      echo $res->hash;
    } catch(Exception $err) {
      echo $err->getMessage();
    }
?>`,
  },
  {
    id: "arduino",
    label: "Arduino",
    link: "https://github.com/blockfrost/blockfrost-arduino",
    logo: <SiArduino />,
    example: `#include "certs.h"
#include "creds.h"
#include "Blockfrost.h"

Blockfrost::CardanoApi *cbapi;

void loop(){
    Blockfrost::Block block;
    {
        {
          auto resp = cbapi->getLatestBlock();
          if(resp.code == 200) {
          block = resp.obj;
          Serial.printf("Last block hash: %s\\n\\r", block.hash.c_str());
          } else {
          Serial.printf("Bad HTTP response code %d\\n\\r", resp.code);
          };
        }
    }
    delay(60000);
}`,
  },
];
