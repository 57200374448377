import React, { useState } from "react";
import Link from "next/link";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";

import { snippets } from "./snippets";
import { Section } from "components/UI/Section";
import { BORDER_RADIUS, COLOR, FONT_SIZE } from "constants/styles";

export const CodeSnippets = () => {
  const [activeLang, setActiveLang] = useState<string>("javascript");

  const langData = snippets.find(s => s.id === activeLang) ?? snippets[0];

  return (
    <Section
      title="We speak your language"
      id="code-snippets"
      background="dark"
      description={
        <>
          <span>
            We provide SDKs for plethora of programming languages. Check out how
            easy it is to use Blockfrost with&nbsp;
          </span>
          <Link href="https://blockfrost.dev/docs/sdks" passHref legacyBehavior>
            <a className="link-light" target="_blank">
              Blockfrost SDKs
            </a>
          </Link>
          <span>.</span>
        </>
      }
    >
      <style jsx>{`
        .code-snippets-wrapper {
          display: flex;
          width: 100%;
          justify-content: center;
        }

        .code-snippets {
          display: flex;
          width: 880px;
          flex-direction: column;
          background: ${COLOR.BG_DARK};
          border-radius: ${BORDER_RADIUS.PRIMARY};
          box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.2);
          overflow: hidden;
        }

        .lang-picker {
          overflow-x: auto;
          display: flex;
          padding: 8px 12px;
          margin-bottom: 8px;
        }

        .lang-picker::-webkit-scrollbar {
          width: 5px;
          height: 8px;
          background-color: ${COLOR.BG_DARK};
        }

        .lang-picker::-webkit-scrollbar-thumb {
          background: #464545;
          border-radius: 5px;
        }

        .lang-item {
          display: flex;
          color: ${COLOR.TEXT_LIGHT};
          align-items: center;
          padding: 12px 16px;
          cursor: pointer;
          border-radius: ${BORDER_RADIUS.PRIMARY};
          transition: 0.3s;
          font-weight: 500;
          font-size: ${FONT_SIZE.LG};
          margin-right: 14px;
        }

        .lang-item + .lang-item {
          margin-left: 4px;
        }

        .lang-item:hover,
        .lang-item-active {
          background: #4c4855;
        }

        .lang-item .logo {
          margin-right: 0.5rem;
        }

        .lang-item .label {
        }

        .logo {
          display: flex;
        }

        .code {
          padding: 0px 24px;
          min-height: 300px;
          max-height: 480px;
          overflow-y: auto;
          font-size: ${FONT_SIZE.LG};
        }

        .code :global(pre::-webkit-scrollbar),
        .code::-webkit-scrollbar {
          width: 8px;
          height: 6px;
          background-color: ${COLOR.BG_DARK}; /* or add it to the track */
        }

        .code :global(pre::-webkit-scrollbar-thumb),
        .code::-webkit-scrollbar-thumb {
          background: #464545;
          border-radius: 5px;
        }

        .code-footer {
          display: flex;
          background: ${COLOR.BG_DARK};
          padding: 16px 16px;
          font-weight: 500;
          font-size: ${FONT_SIZE.SECONDARY};
        }

        .link-light {
          color: #5aacff;
          font-weight: 500;
          transition: 0.3s;
        }
        .link-light:hover,
        .link-light:active {
          color: #4497ea;
          font-weight: 500;
        }
      `}</style>

      <div className="code-snippets-wrapper">
        <div className="code-snippets">
          <div className="lang-picker">
            {snippets.map(lang => (
              <div
                className={`lang-item ${
                  activeLang === lang.id ? "lang-item-active" : ""
                }`}
                key={lang.id}
                tabIndex={0}
                onClick={() => setActiveLang(lang.id)}
              >
                <div className="logo">{lang.logo}</div>
                <div className="label">{lang.label}</div>
              </div>
            ))}
          </div>
          <div className="code">
            <SyntaxHighlighter
              language={langData.id}
              style={atomOneDark}
              // customStyle={{ background: COLOR.BG_DARK }}
            >
              {langData.example}
            </SyntaxHighlighter>
          </div>

          <div className="code-footer">
            <Link href={langData.link} passHref legacyBehavior>
              <a className="link-light" target="_blank">
                View more examples
              </a>
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};
