import React from "react";
import { BREAKPOINT, COLOR, FONT_SIZE } from "constants/styles";
import { Section } from "components/UI/Section";

export const Features = () => {
  return (
    <>
      <div className="wrapper">
        <div className="stripe-bottom"></div>
        <div className="stripe-right"></div>
        <Section
          title={<div className="section-title">Blockchain made simple</div>}
          id="features"
        >
          <div className="reasons">
            <div className="reason">
              <div className="reason-title">Fast and scalable</div>
              <div className="reason-description">
                Featuring over 100 endpoints, our API is tailored for easy
                access to Cardano blockchain. Leveraging high-speed, in-memory
                databases and nodes spread across the globe, we ensure
                consistent high availability and prompt response times.
              </div>
            </div>
            <div className="reason">
              <div className="reason-title">Premium Tx Submit</div>
              <div className="reason-description">
                Our Transaction Submit API come with a priority mempool,
                ensuring your transactions get the attention they deserve.
                Additionally, with rollback protection in place, we safeguard
                your transactions against unexpected blockchain reorganizations.
              </div>
            </div>

            <div className="reason">
              <div className="reason-title">Customer Support</div>
              <div className="reason-description">
                We understand the critical nature of blockchain operations and
                the demand for swift solutions. That&apos;s why our dedicated
                support team is available around the clock, every day. Whether
                you have a technical query or require assistance with API
                endpoints, our experts are on standby to assist you immediately.
              </div>
            </div>

            <div className="reason">
              <div className="reason-title">Build on open-source</div>
              <div className="reason-description">
                <span>
                  We run, build, love and deeply care about open-source.&nbsp;
                </span>
                <a href="https://github.com/blockfrost" className="no-style">
                  Our tools, SDKs, OpenAPI specification and the backend itself
                </a>{" "}
                <span>
                  are all open-source. Do not let your project be locked by
                  proprietary vendors. By choosing our platform, you are
                  embracing a transparent, flexible, and community-driven
                  ecosystem that grows and evolves with your needs.
                </span>
              </div>
            </div>
          </div>
        </Section>
      </div>

      <style jsx>{`
        .stripe,
        .stripe-bottom,
        .stripe-right {
          position: absolute;
          opacity: 0.1;
          overflow: hidden;
          z-index: 1;
          background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.8) 0%,
            rgba(255, 255, 255, 1) 70%,
            rgba(255, 255, 255, 0) 100%
          );
        }

        .stripe-bottom {
          width: 100%;
          height: 50px;
          left: 0px;
          bottom: 0;
        }

        .stripe-right {
          width: 40%;
          height: 50%;
          clip-path: polygon(0 100%, 100% 0, 100% 100%);
          bottom: 0;
          right: 0;
        }

        .section-title {
          color: ${COLOR.TEXT_WHITE};
        }
        .wrapper {
          background: #652bab;
          position: relative;
          overflow: hidden;
          background-image: linear-gradient(135deg, #4a15e9, #8d1fbc);
          margin-top: -100px;
          padding-top: 100px;
        }
        .reasons {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 64px;
          margin: 48px 0px;
        }

        .reason {
          flex: 1 1 100%;
        }
        .reason-title {
          color: ${COLOR.TEXT_WHITE};
          font-size: ${FONT_SIZE.XL};
          font-weight: 500;
          margin-bottom: 8px;
          text-transform: uppercase;
          letter-spacing: 0.1ch;
        }
        .reason-description {
          color: ${COLOR.TEXT_LIGHT};
          font-weight: 500;
          font-size: ${FONT_SIZE.LG};
        }

        @media screen and (max-width: ${BREAKPOINT.SM}px) {
          .reasons {
            grid-template-columns: 1fr;
          }
        }
      `}</style>
    </>
  );
};
